import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CSVLink } from "react-csv";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "@mui/material";
import { faUser, faEllipsisVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { activateUser, deleteUser, getCompanies, getUsers, signInUserAs } from "../../services/axios";

import "./Users.css";
import { UserRole } from "../../common/common";
import { Tooltip, notification } from "antd";
import { formatDateTime, showConfirmEx } from "../../utils/globals";
import AssignVehicle from "./assignVehicle/AssignVehicle";
import { socket } from "../../socket";
import { SET_USER } from "../../redux/store/types";

const getUserLoginStatus = (item) => {
  if (!item || !item.lastActiveAt) {
    return "off";
  }
  let diff = Date.now() - (new Date(item.lastActiveAt)).getTime();
  return diff < 120000 ? "on" : "off";
}
const getUserLoginStatusLabel = (item) => {
  if (!item || !item.lastActiveAt) {
    return "";
  }
  let diff = Date.now() - (new Date(item.lastActiveAt)).getTime();
  return diff < 120000 ? "Online" : ("Last Login: " + formatDateTime(item.lastActiveAt));
}

const Users = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isMobile = useMediaQuery("(max-width: 768px)");

  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);

  const [userList, setUserList] = useState([]);
  const [data, setData] = useState([]);

  const [searchNameText, setSearchNameText] = useState("");
  const [searchUserNameText, setSearchUserNameText] = useState("");
  const [searchEmailText, setSearchEmailText] = useState("");
  const [searchStatusText, setSearchStatusText] = useState("");
  const [searchRoleText, setSearchRoleText] = useState("");
  const [searchFold, setSearchFold] = useState(true);

  const [companies, setCompanies] = useState([]);
  const [searchCompany, setSearchCompany] = useState("");

  const [selectedUsername, setSelectedUsername] = useState("");
  const [isShowModal, setIsShowModal] = useState(false);

  useEffect(() => {
    if (user?.role === UserRole.Client || !isAuthenticated) {
      navigate("/");
    }

    loadUserList();

    getCompanies()
      .then(res => {
        if (res.status == 200) {
          setCompanies(res.data.result);
        }
      })
      .catch(err => { });

    const onUserConnected = async (userData) => {
      // setUserList(userList.map(item => {
      //   if (item._id == userData.userId) {
      //     return { ...item, lastActiveAt: Date.now() };
      //   } else {
      //     return item;
      //   }
      // }));
    }
    const onUserDisconnected = async (userData) => {
      // setUserList(userList.map(item => {
      //   if (item._id == userData.userId) {
      //     return { ...item, lastActiveAt: null };
      //   } else {
      //     return item;
      //   }
      // }));
    }

    socket.on('userConnected', onUserConnected);
    socket.on('userDisconnected', onUserDisconnected);

    return () => {
      socket.off('userConnected', onUserConnected);
      socket.off('userDisconnected', onUserDisconnected);
    }

  }, [isAuthenticated]);

  const loadUserList = async () => {
    var res = await getUsers();
    if (res?.status === 200) {
      setUserList(res?.data?.users);
    }
  };

  useEffect(() => {
    setData(
      userList?.filter((item) => {
        return (
          (searchNameText == "" || item.fname.toLocaleLowerCase().includes(searchNameText.toLocaleLowerCase())) &&
          (searchUserNameText == "" || item.lname.toLocaleLowerCase().includes(searchUserNameText.toLocaleLowerCase())) &&
          (searchRoleText == "" || item.role.toLocaleLowerCase() === searchRoleText.toLocaleLowerCase()) &&
          (searchEmailText == "" || item.email.toLocaleLowerCase().includes(searchEmailText.toLocaleLowerCase())) &&
          (searchCompany == "" || item.company?._id == searchCompany) &&
          (searchStatusText == "" ||
            (searchStatusText === "Active" && item.verified) || (searchStatusText === "Deactivated" && !item.verified))
        );
      })
    );
  }, [userList, searchNameText, searchUserNameText, searchEmailText, searchStatusText, searchRoleText, searchCompany]);

  const handleStateColor = (event) => {
    setSearchStatusText(event.target.value);
  };

  const handleName = (event) => {
    setSearchNameText(event.target.value);
  };

  const handleUseName = (event) => {
    setSearchUserNameText(event.target.value);
  };

  const handleEmail = (event) => {
    setSearchEmailText(event.target.value);
  };

  const handlViewUser = (item) => {
    navigate("/Users/ViewUser", {
      state: {
        address: item?.address,
        name: item?.fname,
        username: item?.lname,
        email: item?.email,
        mobile: item?.phone,
        country: item?.country,
        company: item?.company?.name,
        role: item?.role,
        img: item?.image,
      },
    });
  };

  const handleEditUser = (item) => {
    navigate("/Users/EditUser", {
      state: {
        id: item?._id,
        address: item?.address,
        name: item?.fname,
        username: item?.lname,
        email: item?.email,
        mobile: item?.phone,
        country: item?.country,
        company: item?.company?._id,
        role: item?.role,
        image: item?.image,
        companies: companies
      },
    });
  };

  const handleRemoveUser = async (email, name) => {
    showConfirmEx(`Are You sure to Delete the user ${name}?`)
      .then(async () => {
        var res = await deleteUser({ email: email });
        if (res?.status === 200) {
          notification.success({
            description: "User has been removed successfully!",
          });
          loadUserList();
        } else {
          notification.error({
            description: "Something went wrong!",
          });
        }
      })
      .catch(() => {
      });
  };

  const handleRoleChange = (e) => {
    setSearchRoleText(e.target.value);
  };

  const handleCompanyChange = (e) => {
    setSearchCompany(e.target.value);
  }

  const handleDialogBoxUserState = async (verified, email, name) => {
    showConfirmEx(`Are you sure to ${verified ? 'Deactivate' : 'Activate'} user ${name}?`)
      .then(async () => {
        const sendData = {
          token: token,
          email: email,
          activate: !verified,
        };
        let res = await activateUser(sendData);
        if (res.status === 200) {
          loadUserList();
        } else if (res.data.message) {
          notification.error({
            description: res.data.message,
          });
        }
      })
      .catch(() => {
      });
  };

  const handleLoginAs = async (item) => {
    // if (getUserLoginStatus(item) == "on") {
    //   notification.warning({
    //     description: "User is active now.",
    //   });
    //   return;
    // }

    let signInData = {
      type: "email",
      userId: item.email
    };
    var res = await signInUserAs(signInData);
    if (res.status === 200) {
      dispatch({
        type: SET_USER,
        payload: res.data,
        impersonate: true
      });
      localStorage.setItem("reload", "1");
      navigate("/Dashboard");
    } else {
      notification.error({
        description: res.data.message,
      });
    }
  }

  const handleAssignVehicle = (lname) => {
    setSelectedUsername(lname);
    setIsShowModal(true);
  }

  const handleClear = () => {
    setSearchNameText("");
    setSearchUserNameText("");
    setSearchEmailText("");
    setSearchStatusText("");
    setSearchRoleText("");
    setSearchCompany("");
  };

  return (
    <div className="users-main w-100 h-100">
      {!isMobile ? (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="sub1-div1 d-flex justify-content-between align-items-center w-100">
            <p className="px-5 text-white d-flex justify-content-evenly align-items-center">
              Total Users <span className="ms-3">{data?.length}</span>
            </p>
            <div className="d-flex">
              <CSVLink
                data={data}
                filename="Users"
                style={{ textDecoration: "none" }}
              >
                <div className="tab-button d-flex justify-content-center mx-1 align-items-center">
                  <img src="/assets/export.svg" alt="none" />
                  <button>Export</button>
                </div>
              </CSVLink>
              <div
                className="tab-button d-flex justify-content-center align-items-center mx-1"
                onClick={() => navigate("/Users/AddUser", { state: { companies: companies } })}
              >
                <img src="/assets/addbtn.svg" alt="none" />
                <button>Add User</button>
              </div>
            </div>
          </div>
          <div className="sub2-div1 d-flex flex-column justify-content-end align-items-end w-100">
            <div className="subsub1-sub2-div1 bg-white d-flex flex-column py-2 w-100">
              <div className="sub1-subsub1-sub2-div1 d-flex align-items-center my-2">
                <img src="/assets/Searchwithperson.svg" alt="none" className="search-icon-content" />
                <span>Search user</span>
              </div>
              <div className="sub2-subsub1-sub2-div1 d-flex justify-content-between align-items-center">
                <input
                  className="field-input"
                  type="text"
                  placeholder="Name"
                  value={searchNameText}
                  onChange={handleName}
                />
                <input
                  className="field-input"
                  type="text"
                  placeholder="Username"
                  value={searchUserNameText}
                  onChange={handleUseName}
                />
                <input
                  className="field-input"
                  type="mail"
                  placeholder="Email"
                  value={searchEmailText}
                  onChange={handleEmail}
                />
                <select
                  className="field-select"
                  onChange={handleCompanyChange}
                  value={searchCompany}
                >
                  <option value="">Company</option>
                  {companies && companies.map(item => <option key={item._id} value={item._id}>{item.name}</option>)}
                </select>
                <select
                  className="field-select"
                  onChange={handleRoleChange}
                  value={searchRoleText}
                >
                  <option value="">Role</option>
                  <option value={UserRole.Admin}>Super Admin</option>
                  <option value={UserRole.Manager}>Admin</option>
                  <option value={UserRole.Client}>Client</option>
                </select>
                <select
                  className="field-select"
                  onChange={handleStateColor}
                  value={searchStatusText}
                >
                  <option value="">Status</option>
                  <option style={{ color: "#63D16E" }} value="Active">Active</option>
                  <option style={{ color: "#FF3062" }} value="Deactivated">Deactivated</option>
                </select>
                <div
                  className="tab-button d-flex justify-content-center align-items-center px-4 ms-4"
                  onClick={() => handleClear()}
                >
                  <img src="/assets/clear.svg" alt="none" />
                  <button>Clear</button>
                </div>
              </div>
            </div>
            <div className="div2">
              <div className="subsub1-sub1-users-div2 py-3">
                <p className="mb-0 text-center"></p>
                <p className="mb-0 text-start">Name</p>
                <p className="mb-0 text-start">Username</p>
                <p className="mb-0 text-start">Email</p>
                <p className="mb-0 text-start">Mobile No.</p>
                <p className="mb-0 text-start">Company</p>
                <p className="mb-0 text-start">Role</p>
                <p className="mb-0 text-start">City & Country</p>
                <p className="mb-0 text-start">Status</p>
                <p className="mb-0 text-center"></p>
              </div>
              <div className="sub2-div2 overflow-auto">
                {data?.map((item, index) => {
                  return (
                    <div id="import-users" key={index}>
                      <p id="sub1-import-users">{index + 1}</p>
                      <div className="subsub1-sub2-users-div2">
                        <div className="item">
                          <Tooltip placement="bottom" title={getUserLoginStatusLabel(item)}>
                            <div className={`login-status cursor-pointer ${getUserLoginStatus(item)}`}></div>
                          </Tooltip>
                          <div className="d-flex justify-content-center align-items-center"  style={{ width: '7rem', height: '7rem', marginLeft: "1rem" }}>
                            <img
                              crossOrigin="*" alt="none"
                              src={item.image ? process.env.REACT_APP_URL + "/" + item.image : "/assets/common_user.png"}
                              className="person object-fit-cover rounded-circle"
                            />
                          </div>
                        </div>
                        <p className="item">{item?.fname}</p>
                        <p className="item">{item?.lname}</p>
                        <p className="item">{item?.email}</p>
                        <p className="item">{!item?.phone?.startsWith("+") && '+'}{item?.phone}</p>
                        <p className="item">{item?.company?.name}</p>
                        <p className="item">{item?.role}</p>
                        <p className="item">{item?.country}</p>
                        <p
                          className="item"
                          style={{ color: item?.verified ? "#63D16E" : "#FF3062" }}
                        >
                          {item?.verified ? "Activated" : "Deactivated"}
                        </p>
                        <div className="users-dropdown item d-flex position-relative">
                          <FontAwesomeIcon
                            className="dropdown-toggle devices-dropdown-img px-3"
                            icon={faEllipsisVertical}
                            data-bs-toggle="dropdown"
                            aria-expanded="false"
                          />
                          <div className="dropdown-menu users-dropdown-div">
                            <div className="sub1-users-dropdown-div mx-3 d-flex flex-column justify-content-evenly h-100">
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handlViewUser(item)}
                              >
                                <img
                                  src="/assets/view.svg"
                                  alt="none"
                                  style={{ width: "1.5rem", height: "2rem" }}
                                />
                                <p className="mb-0 ms-3">View</p>
                              </div>
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleEditUser(item)}
                              >
                                <img src="/assets/edit.svg" alt="none" />
                                <p className="mb-0 ms-3">Edit</p>
                              </div>
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleRemoveUser(item?.email, item?.lname)}
                              >
                                <img src="/assets/remove.svg" alt="none" />
                                <p className="mb-0 ms-3">Remove</p>
                              </div>
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleDialogBoxUserState(item?.verified, item?.email, item?.lname)}
                              >
                                <img src={item.verified ? "/assets/deactivate.svg" : "/assets/Activate.svg"} alt="none" />
                                <p className="mb-0 ms-3">
                                  {item.verified ? "Deactivate" : "Active"}
                                </p>
                              </div>
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => { }}
                              >
                                <img src="/assets/chat.svg" alt="none" />
                                <p className="mb-0 ms-3">Chat</p>
                              </div>
                              {(user?.role == UserRole.Admin && user?.id != item._id) &&
                                <div
                                  className="d-flex align-items-center cursor-pointer action-item"
                                  onClick={() => handleLoginAs(item)}
                                >
                                  <img src="/assets/login_as.svg" alt="none" />
                                  <p className="mb-0 ms-3">Impersonate</p>
                                </div>
                              }
                              <div
                                className="d-flex align-items-center cursor-pointer action-item"
                                onClick={() => handleAssignVehicle(item?.lname)}
                              >
                                <img src="/assets/assign_vehicle.svg" alt="none" />
                                <p className="mb-0 ms-3">Assign Vehicle</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <div className="tab mobile-title mb-4">
            <div
              aria-current="page"
              className="px-3 d-flex justify-content-center py-2"
              style={{
                color: "white",
                backgroundColor: "#1A2678",
                borderRadius: "30px",
              }}
            >
              <div className="d-flex content align-items-center">
                <FontAwesomeIcon className="me-4" icon={faUser} />
                Users
              </div>
            </div>
          </div>
          <div className="sub1-div1 d-flex justify-content-between align-items-end w-100 p-0 my-3">
            <p className="px-3 text-white d-flex justify-content-evenly align-items-center mb-0">
              Total Users <span className="ms-3">{data?.length}</span>
            </p>
            <div className="d-flex">
              <CSVLink
                data={data}
                filename="Users"
                style={{ textDecoration: "none" }}
              >
                <div className="tab-button d-flex justify-content-evenly mx-1 align-items-center">
                  <img src="/assets/export.svg" alt="none" />
                  <button>Export</button>
                </div>
              </CSVLink>
              <div
                className="tab-button d-flex justify-content-evenly align-items-center mx-1"
                onClick={() => navigate("/Users/AddUser")}
              >
                <img src="/assets/addbtn.svg" alt="none" />
                <button>Add User</button>
              </div>
            </div>
          </div>
          <div className="subsub1-sub2-div1 bg-white d-flex flex-column py-2 px-4 w-100">
            <div className="sub1-subsub1-sub2-div1 d-flex align-items-center p-2" onClick={() => setSearchFold(!searchFold)}>
              <img src="/assets/Searchwithperson.svg" alt="none" className="search-icon-content" />
              <span>Search user</span>
              <img
                className="ms-auto accordion"
                style={!searchFold ? { transform: "rotate(180deg)" } : { transform: "none" }}
                src="/assets/arrow-down.png"
                alt="Arrow Down"
              />
            </div>
            {!searchFold && (
              <div className="sub2-subsub1-sub2-div1 d-flex flex-column px-0">
                <div className="d-flex w-100">
                  <div className="col-6 px-1">
                    <input
                      className="field-input"
                      type="text"
                      placeholder="Name"
                      value={searchNameText}
                      onChange={handleName}
                    />
                  </div>
                  <div className="col-6 px-1">
                    <input
                      className="field-input"
                      type="text"
                      placeholder="Username"
                      value={searchUserNameText}
                      onChange={handleUseName}
                    />
                  </div>
                </div>
                <div className="d-flex w-100">
                  <div className="col-6 px-1">
                    <input
                      className="field-input"
                      type="mail"
                      placeholder="Email"
                      value={searchEmailText}
                      onChange={handleEmail}
                    />
                  </div>
                  <div className="col-6 px-1">
                    <select className="field-select">
                      <option value="">Company</option>
                      {companies && companies.map(item => <option value={item._id}>{item.name}</option>)}
                    </select>
                  </div>
                </div>
                <div className="d-flex w-100">
                  <div className="col-6 px-1">
                    <select
                      className="field-select"
                      onChange={handleRoleChange}
                      value={searchRoleText}
                    >
                      <option value="">Role</option>
                      <option value={UserRole.Admin}>Super Admin</option>
                      <option value={UserRole.Manager}>Admin</option>
                      <option value={UserRole.Client}>Client</option>
                    </select>
                  </div>
                  <div className="col-6 px-1">
                    <select
                      className="field-select"
                      onChange={handleStateColor}
                      value={searchStatusText}
                    >
                      <option value="">Status</option>
                      <option style={{ color: "#63D16E" }} value="Active">Active</option>
                      <option style={{ color: "#FF3062" }} value="Deactivated">Deactivated</option>
                    </select>
                  </div>
                </div>
                <div
                  className="tab-button d-flex justify-content-center align-items-center px-4 ms-auto py-1"
                  onClick={() => handleClear()}
                >
                  <img src="/assets/clear.svg" alt="none" />
                  <button>Clear</button>
                </div>
              </div>
            )}
          </div>
          <div className={`sub2-div2 d-flex flex-column px-4 w-100 overflow-auto ms-0 ${searchFold && "folded"}`}>
            {data?.map((item, index) => {
              return (
                <div id="import-users" key={index}>
                  <p id="sub1-import-users">{index + 1}</p>
                  <div className="subsub1-sub2-users-div2 d-flex align-items-center py-2">
                    <div className="item col-2 align-items-start px-1">
                      <img
                        crossOrigin="*"
                        src={item.image ? process.env.REACT_APP_URL + "/" + item.image : "/assets/common_user.png"}
                        alt="none"
                        className="person item object-fit-cover rounded-circle"
                      />
                    </div>
                    <div className="d-flex flex-column col-10 px-2">
                      <div className="d-flex">
                        <p className="mb-0 px-2 col-4 text-white">Name</p>
                        <p className="mb-0 px-2 col-8 item justify-content-start">
                          {item?.fname}
                        </p>
                      </div>
                      <div className="d-flex">
                        <p className="mb-0 px-2 col-4 text-white">Username</p>
                        <p className="mb-0 px-2 col-8 item justify-content-start">
                          {item?.lname}
                        </p>
                      </div>
                      <div className="d-flex">
                        <p className="mb-0 px-2 col-4 text-white">Email</p>
                        <p className="mb-0 px-2 col-8 item justify-content-start">
                          {item?.email}
                        </p>
                      </div>
                      <div className="d-flex">
                        <p className="mb-0 px-2 col-4 text-white">Mobile No.</p>
                        <p className="mb-0 px-2 col-8 item justify-content-start">
                          {!item?.phone.startsWith("+") && '+'}{item?.phone}
                        </p>
                      </div>
                      <div className="d-flex">
                        <p className="mb-0 px-2 col-4 text-white">Company</p>
                        <p className="mb-0 px-2 col-8 item justify-content-start">
                          {item?.company?.name}
                        </p>
                      </div>
                      <div className="d-flex">
                        <p className="mb-0 px-2 col-4 text-white">Role</p>
                        <p className="mb-0 px-2 col-8 item justify-content-start">
                          {item?.role}
                        </p>
                      </div>
                      <div className="d-flex">
                        <p className="mb-0 px-2 col-4 text-white">Country</p>
                        <p className="mb-0 px-2 col-8 item justify-content-start">
                          {item?.country}
                        </p>
                      </div>
                      <div className="d-flex">
                        <p className="mb-0 px-2 col-4 text-white">Status</p>
                        <div className="mb-0 px-2 col-8 item justify-content-between">
                          <p
                            className="item mb-0 justify-content-start"
                            style={{ color: item?.verified ? "#63D16E" : "#FF3062" }}
                          >
                            {item?.verified ? "Activated" : "Deactivated"}
                          </p>
                          <div className="users-dropdown d-flex position-relative">
                            <FontAwesomeIcon
                              className="dropdown-toggle devices-dropdown-img px-3"
                              icon={faEllipsisVertical}
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            />
                            <div className="dropdown-menu users-dropdown-div">
                              <div className="sub1-users-dropdown-div mx-3 d-flex flex-column justify-content-evenly h-100">
                                <div
                                  className="d-flex align-items-center cursor-pointer action-item"
                                  onClick={() => handlViewUser(item)}
                                >
                                  <img
                                    src="/assets/view.svg"
                                    alt="none"
                                    style={{ width: "1.5rem", height: "2rem" }}
                                  />
                                  <p className="mb-0 ms-3">View</p>
                                </div>
                                <div
                                  className="d-flex align-items-center cursor-pointer action-item"
                                  onClick={() => handleEditUser(item)}
                                >
                                  <img src="/assets/edit.svg" alt="none" />
                                  <p className="mb-0 ms-3">Edit</p>
                                </div>
                                <div
                                  className="d-flex align-items-center cursor-pointer action-item"
                                  onClick={() => handleRemoveUser(item?.email)}
                                >
                                  <img src="/assets/remove.svg" alt="none" />
                                  <p className="mb-0 ms-3">Remove</p>
                                </div>
                                <div
                                  className="d-flex align-items-center cursor-pointer action-item"
                                  onClick={() => handleDialogBoxUserState(item?.verified, item?.email, item?.lname)}
                                >
                                  <img src={item.verified ? "/assets/deactivate.svg" : "/assets/Activate.svg"} alt="none" />
                                  <p className="mb-0 ms-3">
                                    {item.verified ? "Deactivate" : "Active"}
                                  </p>
                                </div>
                                <div
                                  className="d-flex align-items-center cursor-pointer action-item"
                                  onClick={() => { }}
                                >
                                  <img src="/assets/chat.svg" alt="none" />
                                  <p className="mb-0 ms-3">Chat</p>
                                </div>
                                {(user?.role == UserRole.Admin && user?.id != item._id) &&
                                  <div
                                    className="d-flex align-items-center cursor-pointer action-item"
                                    onClick={() => handleLoginAs(item)}
                                  >
                                    <img src="/assets/login_as.svg" alt="none" />
                                    <p className="mb-0 ms-3">Impersonate</p>
                                  </div>
                                }
                                <div
                                  className="d-flex align-items-center cursor-pointer action-item"
                                  onClick={() => handleAssignVehicle(item?.lname)}
                                >
                                  <img src="/assets/assign_vehicle.svg" alt="none" />
                                  <p className="mb-0 ms-3">Assign Vehicle</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}

      <AssignVehicle
        isShowModal={isShowModal}
        username={selectedUsername}
        onClose={() => setIsShowModal(false)}
      />

    </div>
  );
};

export default Users;
