import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import ReactApexChart from "react-apexcharts";

import { Select } from "antd";
import MapContainer from "../commons/maps/MapContainer";
import "./Dashboard.css";


const pieChartOptions = {
  chart: {
    type: 'donut',
    dropShadow: {
      enabled: true,
      color: '#111',
      top: -1,
      left: 3,
      blur: 3,
      opacity: 0.2
    }
  },
  colors: ['#28A745', '#D01400', '#F89823'],
  labels: ['Online', 'Offline', 'Idle'],
  stroke: {
    width: 0,
  },
  plotOptions: {
    pie: {
      donut: {
        labels: {
          show: true,
          total: {
            showAlways: true,
            show: true,
            color: '#21213D',
            fontSize: 16,
            formatter: function (w) {
              return w.globals.series[0] + w.globals.series[1];
            }
          },
          value: {
            color: '#6D9EFD',
            fontSize: 16,
          }
        }
      }
    }
  },
  dataLabels: {
    enabled: false,
  },
  fill: {
    opacity: 1,
    pattern: {
      enabled: false,
    },
  },
  states: {
    hover: {
      filter: 'none'
    }
  },
  legend: {
    enabled: false,
    show: false
  },
}
const Dashboard = () => {
  const navigate = useNavigate();
  const locationState = useLocation().state;
  const devices = useSelector((state) => state.devicesList.devices);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const isMobile = useMediaQuery("(max-width: 768px)");

  const [mapCenter, setMapCenter] = useState(locationState?.point);
  const [selectImei, setSelectImei] = useState(null);
  const [searchText, setSearchText] = useState(null);

  const [showStatusPanel, setShowStatusPanel] = useState(true);
  const [vehicleStatuses, setVehicleStatuses] = useState([0, 0, 0]);

  const [isShowAll, setIsShowAll] = useState(locationState?.point == undefined ? false : true);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/");
    }
  }, [isAuthenticated]);

  useEffect(() => {
    let online = 0, offline = 0, idle = 0;
    for (const device of devices) {
      if (device.connected) {
        online++;
        if (device.idle) {
          idle++;
        }
      } else {
        offline++;
      }
    }
    setVehicleStatuses([online, offline, idle]);
  }, [devices]);

  useEffect(() => {
    setMapCenter(locationState?.point);

    if (localStorage.getItem("reload") == "1") {
      localStorage.removeItem("reload");
      navigate(0);
    }
  }, [locationState]);

  const handleSearch = (value) => {
    const device = devices?.find(item => item.deviceImei == value);
    if (device) {
      setMapCenter({ lat: device.lat, lng: device.lng });
      setSelectImei(device.deviceImei);
    } else {
      setSelectImei(null);
    }
    setSearchText(value);
  }

  useEffect(() => {
    if (!isShowAll) {
      let count = 0;
      for (const device of devices) {
        if (device.lat != 0 && device.lng != 0) {
          count++;
        }
      }
      if (count >= 2) {
        setTimeout(() => {
          setIsShowAll(true);
        }, 1000);
      }
    }
  }, [devices]);

  return (
    <div className="map-container position-relative d-flex justify-content-center w-100">
      <Select
        showSearch
        allowClear
        className={`position-absolute vehicle-search-input ${isMobile ? 'mobile' : ''}`}
        placeholder="Search Vehicle"
        filterOption={(input, option) =>
          (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
        }
        options={devices?.map((item) => ({
          label: `${item.vehicleName} (${item.deviceImei})`, value: item.deviceImei
        }))}
        value={searchText}
        onChange={(value) => handleSearch(value)}
      />

      <div className={`position-absolute vehicle-status-panel ${!showStatusPanel && 'hidden'}`}>
        <div className="title">Device Status</div>
        <div className="d-flex flex-row">
          <div className="flex-grow-1 d-flex align-items-center">
            <ReactApexChart
              options={pieChartOptions}
              series={vehicleStatuses}
              type="donut"
              width="100%"
              height="180px"
            />
          </div>
          <div className="flex-grow-0 d-flex flex-column gap-2">
            <div className="d-flex flex-row">
              <div className="circle" style={{ backgroundColor: "#28A745" }}></div>
              <div>
                <div style={{ color: "#7C7C82" }}>Online</div>
                <div>{vehicleStatuses[0]}</div>
              </div>
            </div>
            <div className="d-flex flex-row">
              <div className="circle" style={{ backgroundColor: "#D01400" }}></div>
              <div>
                <div style={{ color: "#7C7C82" }}>Offline</div>
                <div>{vehicleStatuses[1]}</div>
              </div>
            </div>
            <div className="d-flex flex-row">
              <div className="circle" style={{ backgroundColor: "#F89823" }}></div>
              <div>
                <div style={{ color: "#7C7C82" }}>Idle</div>
                <div>{vehicleStatuses[2]}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="position-absolute toggle-button" onClick={() => setShowStatusPanel(prev => !prev)}>
          <FontAwesomeIcon className="left" icon={faChevronLeft} />
          <FontAwesomeIcon className="right" icon={faChevronRight} />
        </div>
      </div>

      <MapContainer isShowAll={isShowAll} devices={devices} point={mapCenter} selectImei={selectImei} />
    </div>
  );
};

export default Dashboard;
